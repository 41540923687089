import { Box, Stack, Typography } from "@mui/material";
import LanguageSelector from "./LanguageSelector";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const StyledLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
});

export const HeaderWrapper = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      minHeight: "100vh",
      display: "grid",
      gridTemplateRows: "auto 1fr",
    }}
  >
    <Stack
      component="header"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        boxShadow: 1,
        py: 1,
        px: 3,
      }}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <StyledLink to={"/"}>
          <Typography variant="h4">UEMS</Typography>
        </StyledLink>
      </Stack>
      <LanguageSelector />
    </Stack>

    <main>{children}</main>
  </Box>
);
