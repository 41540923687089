import {
  FALLBACK_LANGUAGE_CODE,
  CHOSEN_LANGUAGE_KEY,
  COOKIE_CONFIG,
} from "@/shared/constants";
import i18n from "i18next";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { supportedLanguages } from "./languages";
import { auth } from "@/lib/firebase";
import Cookies from "js-cookie";

// resolved language: local storage key -> browser language -> fallback
const resolvedLanguage = Cookies.get(CHOSEN_LANGUAGE_KEY) || "ru";
// replace "ru" with navigator.language when any other language beside "ru" is supported
// || navigator.language;

i18n.on("languageChanged", (language: string) => {
  Cookies.set(CHOSEN_LANGUAGE_KEY, language, COOKIE_CONFIG);
  document.title = i18n.t("appTitle", {
    defaultValue: "UEMS",
  });
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    lng: resolvedLanguage,
    load: "languageOnly",
    fallbackLng: FALLBACK_LANGUAGE_CODE,
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: supportedLanguages.map(lang => lang.code),
  })
  .then(() => {
    // set language for firebase auth (oauth popups, email etc)
    auth.languageCode = i18n.resolvedLanguage || FALLBACK_LANGUAGE_CODE;
  });

export default i18n;
