import "@/i18n/i18n";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "modern-normalize/modern-normalize.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import FinishGoogleAuth from "./components/FinishGoogleAuth";
import Loading from "./components/Loading";
import Login from "./components/Login";
import PasswordRecovery from "./components/PasswordRecovery";
import SignUp from "./components/SignUp";
import ErrorPage from "./error-page";
import "./fonts";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/Root";
import {
  FINISH_GOOGLE_AUTH_ROUTE,
  LOGIN_ROUTE,
  RECOVER_PASSWORD_ROUTE,
  REGISTER_ROUTE,
} from "./shared/constants";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <Navigate to={LOGIN_ROUTE} replace /> },
      { path: LOGIN_ROUTE, element: <Login /> },
      { path: REGISTER_ROUTE, element: <SignUp /> },
      { path: RECOVER_PASSWORD_ROUTE, element: <PasswordRecovery /> },
      { path: FINISH_GOOGLE_AUTH_ROUTE, element: <FinishGoogleAuth /> },
    ],
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading />}>
        <RouterProvider router={router} />
      </Suspense>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
