import { HeaderWrapper } from "@/components/HeaderWrapper";
import { connectToSuspense } from "@/lib/utils";
import UserService from "@/services/user";
import { PROFILE_URL } from "@/shared/environmentVariables";
import { Outlet } from "react-router-dom";

// handle session check success
const promise = UserService.checkSession().then(result => {
  if (result) {
    window.location.href = PROFILE_URL;
  }

  return result;
});

// handle loading after session check
const resource = connectToSuspense<boolean>(promise);

export default function Root() {
  const response = resource.read();

  // handle error after session check
  const isError = response instanceof Error;
  if (isError) {
    throw new Error(response.message);
  }

  return (
    <HeaderWrapper>
      <Outlet />
    </HeaderWrapper>
  );
}
