import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export default function BackButton({ to, text, onClick }: Props) {
  return (
    <Link to={to} onClick={onClick}>
      <Button variant="outlined" startIcon={<ArrowBackIcon />} sx={{ mb: 2 }}>
        {text}
      </Button>
    </Link>
  );
}
