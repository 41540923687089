import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  inMemoryPersistence,
  setPersistence,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB2qPlwjk6Qfg6XtOHOP2yXWfdl7qoWBkw",
  authDomain: "uems-4856a.firebaseapp.com",
  projectId: "uems-4856a",
  storageBucket: "uems-4856a.appspot.com",
  messagingSenderId: "419400510562",
  appId: "1:419400510562:web:017686f6af9da032f5353b",
  measurementId: "G-J2WNX5QV8H",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
// As httpOnly cookies are to be used, do not persist any state client side.
setPersistence(auth, inMemoryPersistence);
const provider = new GoogleAuthProvider();

export { auth, provider };
