import { RouterState } from "@/interfaces/Router";
import { auth, provider } from "@/lib/firebase";
import {
  isEmailValid,
  isPasswordValid,
  onLoginSuccess,
  translateError,
} from "@/lib/utils";
import UserService from "@/services/user";
import {
  FINISH_GOOGLE_AUTH_ROUTE,
  RECOVER_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  USER_TOKEN,
} from "@/shared/constants";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
  getAdditionalUserInfo,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FcGoogle } from "react-icons/fc";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import LoadingOverlay from "./LoadingOverlay";
import PasswordField from "./PasswordField";

// Decrese popup cancel delay. Sketchy? yes
(function () {
  const originalSetTimeout: typeof setTimeout = window.setTimeout;

  window.setTimeout = function (
    handler: TimerHandler,
    timeout?: number | undefined,
    ...args: any[]
  ): number {
    // Check if the delay matches Firebase's *Timeout.AUTH*EVENT
    if (timeout === 8000) {
      timeout = 1000;
    }
    return originalSetTimeout(handler, timeout, ...args);
  } as typeof setTimeout;

  // Preserve the __promisify__ property
  (window.setTimeout as any).__promisify__ = originalSetTimeout.__promisify__;
})();

// style links from react-router-dom to match mui buttons
const StyledLink = styled(Link)({
  color: "inherit",
});

// validate inputs on submit and onChange
export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation() as { state: RouterState };
  const emailFromRouter = state?.email || "";

  const [wasValidated, setWasValidated] = useState(false);
  const [email, setEmail] = useState(emailFromRouter);
  const [password, setPassword] = useState("");
  // error is ref, not state, because it's value shouldn't be changing together with inputs. And setWasValidated will trigger re-render anyway when error should be shown
  const errorRef = useRef<string | null>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onError = (error: unknown) => {
    errorRef.current = translateError(error);
    console.error(error);

    // reset errors, since server return an error, any field could be invalid
    setWasValidated(false);
  };

  const signInWithEmailMutation = useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await user.getIdToken();

      await UserService.login({ idToken });

      return user;
    },
    onSuccess: onLoginSuccess,
    onError,
  });

  const signInWithGoogleMutation = useMutation({
    mutationFn: async () => {
      const result = await signInWithPopup(auth, provider);

      return result;
    },
    onSuccess: async result => {
      const token = await result.user.getIdToken();

      const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

      // collect phone and complete signup with google
      if (isNewUser) {
        sessionStorage.setItem(USER_TOKEN, token);
        navigate(`/${FINISH_GOOGLE_AUTH_ROUTE}`);
      } else {
        await UserService.login({ idToken: token });
        onLoginSuccess();
      }
    },
    onError,
  });

  const navigateTo = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: string
  ) => {
    event.preventDefault();

    const emailValue = emailRef.current?.value || "";
    const state: RouterState = { email: emailValue };

    navigate(route, {
      state,
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    validateEmail(emailValue);
  };

  const validateEmail = (email: string): boolean => {
    if (!isEmailValid(email)) {
      emailRef.current?.setCustomValidity(t("errors.invalidEmail"));
      return true;
    } else {
      emailRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);

    validatePassword(passwordValue);
  };

  const validatePassword = (password: string): boolean => {
    if (!isPasswordValid(password)) {
      passwordRef.current?.setCustomValidity(t("errors.invalidPassword"));
      return true;
    } else {
      passwordRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    errorRef.current = null;
    setWasValidated(true);

    const form = event.currentTarget;

    validateEmail(email);
    validatePassword(password);
    if (!form.checkValidity()) {
      return;
    }

    signInWithEmailMutation.mutate({ email, password });
  };

  const isLoading =
    signInWithEmailMutation.isPending || signInWithGoogleMutation.isPending;

  return (
    <AuthWrapper>
      <LoadingOverlay isLoading={isLoading} />

      <Box component={"form"} noValidate sx={{ p: 6 }} onSubmit={handleSubmit}>
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          {t("login.title")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t("login.subtitle")}
        </Typography>

        <Stack spacing={3}>
          {errorRef.current !== null && (
            <Alert severity="error"> {errorRef.current} </Alert>
          )}
          <TextField
            fullWidth
            type="email"
            inputRef={emailRef}
            value={email}
            onChange={handleEmailChange}
            label={t("login.email")}
            error={wasValidated && emailRef.current?.validity.valid === false}
            helperText={wasValidated && emailRef.current?.validationMessage}
          />

          <Box>
            <PasswordField
              id="password"
              label={t("shared.password")}
              value={password}
              onChange={handlePasswordChange}
              inputRef={passwordRef}
              error={
                wasValidated && passwordRef.current?.validity.valid === false
              }
              helperText={
                wasValidated && passwordRef.current?.validationMessage
              }
            />
            <StyledLink
              to={`/${RECOVER_PASSWORD_ROUTE}`}
              onClick={event => navigateTo(event, `/${RECOVER_PASSWORD_ROUTE}`)}
            >
              <Typography variant="subtitle2" color="primary">
                {t("login.forgotPassword")}
              </Typography>
            </StyledLink>
          </Box>

          <Button type="submit" fullWidth variant="contained" color="primary">
            {t("login.signIn")}
          </Button>

          <Alert severity="info" color="info">
            {`${t("login.notRegistered")} `}
            <StyledLink
              to={`/${REGISTER_ROUTE}`}
              onClick={event => navigateTo(event, `/${REGISTER_ROUTE}`)}
            >
              {t("login.signUp")}
            </StyledLink>
          </Alert>

          <Divider>
            <Typography sx={{ color: "text.secondary" }}>
              {t("login.divider")}
            </Typography>
          </Divider>
          <Button
            fullWidth
            variant="outlined"
            startIcon={<FcGoogle />}
            onClick={() => signInWithGoogleMutation.mutate()}
          >
            {t("login.signInWithGoogle")}
          </Button>
        </Stack>
      </Box>
    </AuthWrapper>
  );
}
