import { Box, FormHelperText, styled, TextFieldProps } from "@mui/material";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

// style more like mui input
const StyledPhoneInput = styled(PhoneInput)({
  ".special-label:has(+.form-control:focus)": {
    color: "#1976d2",
  },
  ".special-label:has(+.invalid-number)": {
    color: "#d32f2f !important",
  },
  ".special-label": {
    left: "9px",
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "12px",
  },
  ".invalid-number.form-control::placeholder": {
    color: "#d32f2f",
  },
});

interface CustomPhoneInputProps extends Omit<TextFieldProps, "onChange"> {
  value: string;
  onChange: (phone: string) => void;
  label?: string;
  error?: boolean;
  errorMessage?: string;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = forwardRef(
  ({ value, onChange, label, error, errorMessage, ...props }, ref) => {
    const { i18n } = useTranslation();
    const resolvedLanguage = i18n.resolvedLanguage;

    return (
      <Box>
        <StyledPhoneInput
          // try and guess the country from language
          country={resolvedLanguage}
          value={value}
          onChange={(phone: string) => onChange(phone)}
          specialLabel={label}
          enableSearch={true}
          inputStyle={{
            width: "100%",
          }}
          isValid={!error}
          inputProps={{
            ref,
            ...props,
          }}
        />

        {error && (
          <FormHelperText error sx={{ marginLeft: 1.5 }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Box>
    );
  }
);

export default CustomPhoneInput;
