import { supportedLanguages } from "@/i18n/languages";
import { changeLanguage, getCachedLanguage } from "@/lib/utils";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Image = styled.img`
  border-radius: 50%;
  width: 25px;
  margin-right: 10px;
  border: 1px solid gray;
`;

const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() =>
    getCachedLanguage(i18n)
  );

  const handleLanguageSelect = (code: string) => {
    changeLanguage(i18n, code);
    setSelectedLanguage(code);
  };

  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        autoWidth
        value={selectedLanguage}
        onChange={event => handleLanguageSelect(event.target.value as string)}
      >
        {supportedLanguages.map(language => (
          <MenuItem
            key={language.code}
            value={language.code}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Image src={language.icon} alt={language.common} />
              <Typography variant="body1">{language.common}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
