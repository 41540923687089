import { APP_DOMAIN } from "./environmentVariables";

export const REGISTER_ROUTE = "register";
export const LOGIN_ROUTE = "login";
export const RECOVER_PASSWORD_ROUTE = "forgot-password";
export const FINISH_GOOGLE_AUTH_ROUTE = "finish-google-auth";

export const EMAIL_REGEX = /^[\w.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

export const CHOSEN_LANGUAGE_KEY = "chosenLanguage";
export const FALLBACK_LANGUAGE_CODE = "ru";
export const EXPECTED_SIGN_IN_KEY = "expectedSignIn";
export const PROFILE_KEY = "profile";
export const COOKIE_CONFIG = {
  expires: 365,
  secure: true,
  sameSite: "Lax",
  domain: APP_DOMAIN,
} as const;
export const USER_TOKEN = "userToken";

export interface ProfileCookie {
  avatar: string;
  fullName: string;
  id: string;
}
