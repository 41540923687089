import { RouterState } from "@/interfaces/Router";
import { auth } from "@/lib/firebase";
import { isEmailValid, translateError } from "@/lib/utils";
import { LOGIN_ROUTE } from "@/shared/constants";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { sendPasswordResetEmail } from "firebase/auth";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import BackButton from "./BackButton";
import LoadingOverlay from "./LoadingOverlay";

interface Notification {
  type: AlertColor;
  message: string;
}

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation() as { state: RouterState };
  const emailFromRouter = state?.email || "";

  const [wasValidated, setWasValidated] = useState(false);
  const [email, setEmail] = useState(emailFromRouter);
  const emailRef = useRef<HTMLInputElement>(null);
  // error is ref, not state, because it's value shouldn't be changing together with inputs. And setWasValidated will trigger re-render anyway when error should be shown
  const notificationRef = useRef<Notification | null>(null);

  const resetPasswordMutation = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      await sendPasswordResetEmail(auth, email);
    },
    onSuccess() {
      notificationRef.current = {
        type: "success",
        message: t("resetPassword.success"),
      };
    },
    onError: (error: unknown) => {
      notificationRef.current = {
        type: "error",
        message: translateError(error),
      };
      console.error(error);

      // reset errors, since server return an error, any field could be invalid
      setWasValidated(false);
    },
  });

  const navigateTo = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: string
  ) => {
    event.preventDefault();

    const emailValue = emailRef.current?.value || "";
    const state: RouterState = { email: emailValue };

    navigate(route, {
      state,
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    validateEmail(emailValue);
  };

  const validateEmail = (email: string): boolean => {
    if (!isEmailValid(email)) {
      emailRef.current?.setCustomValidity(t("errors.invalidEmail"));
      return true;
    } else {
      emailRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    notificationRef.current = null;
    setWasValidated(true);

    const form = event.currentTarget;

    validateEmail(email);
    if (!form.checkValidity()) {
      return;
    }

    resetPasswordMutation.mutate({ email });
  };

  return (
    <AuthWrapper>
      <LoadingOverlay isLoading={resetPasswordMutation.isPending} />

      <Box component={"form"} noValidate sx={{ p: 6 }} onSubmit={handleSubmit}>
        <BackButton
          to={`/${LOGIN_ROUTE}`}
          onClick={event => navigateTo(event, `/${LOGIN_ROUTE}`)}
          text={t("shared.backToLogin")}
        />

        <Typography variant="h3" sx={{ marginBottom: 1 }}>
          {t("resetPassword.title")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t("resetPassword.subtitle")}
        </Typography>

        <Stack spacing={3}>
          {notificationRef.current !== null && (
            <Alert severity={notificationRef.current.type}>
              {notificationRef.current.message}
            </Alert>
          )}

          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            inputRef={emailRef}
            fullWidth
            error={wasValidated && emailRef.current?.validity.valid === false}
            helperText={wasValidated && emailRef.current?.validationMessage}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            {t("resetPassword.submit")}
          </Button>
        </Stack>
      </Box>
    </AuthWrapper>
  );
};

export default PasswordRecovery;
