import { Box } from "@mui/material";
import backgroundImage from "@/assets/background.webp";

const AuthWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        sx={{
          boxShadow: 10,
          borderRadius: 1,
          overflow: "hidden",
          maxWidth: "80vw",
          minHeight: "80%",
          display: "grid",
          gridTemplateColumns: "minmax(150px, 3fr) minmax(250px, 5fr)",
          alignItems: "center",
        }}
      >
        {children}

        <Box
          component="img"
          src={backgroundImage}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};

export default AuthWrapper;
