import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import styled from "styled-components";
import { HeaderWrapper } from "./components/HeaderWrapper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
`;

const Message = styled.p`
  font-size: 1.5rem;
`;

export default function ErrorPage() {
  const error = useRouteError();
  const { t } = useTranslation();

  console.error(error);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <HeaderWrapper>
      <Container>
        <Title>{t("error.title")}</Title>

        <Message>{t("error.description")}</Message>

        {isRouteErrorResponse(error) ? (
          <>
            <Message>
              <i>
                {error.status}: {error.statusText}
              </i>
            </Message>

            {error.data && <Message>{error.data}</Message>}
          </>
        ) : (
          <Message>
            <i>{error.statusText || error.message}</i>
          </Message>
        )}

        <Button variant="contained" color="primary" onClick={handleReload}>
          {t("error.reload")}
        </Button>
      </Container>
    </HeaderWrapper>
  );
}
