import { supportedLanguages } from "@/i18n/languages";
import { PROFILE_URL } from "@/shared/environmentVariables";
import { ServerError } from "@/shared/errors";
import { FirebaseError } from "firebase/app";
import i18next, { i18n } from "i18next";
import { EMAIL_REGEX, FALLBACK_LANGUAGE_CODE } from "../shared/constants";
import { auth } from "./firebase";

export const isPasswordValid = (password: string): boolean => {
  return password.length >= 6;
};

export const isEmailValid = (email: string): boolean => EMAIL_REGEX.test(email);

export const isPhoneValid = (phone: string): boolean => {
  // general check that some phone is typed in
  return phone.length > 5;
};

export const changeLanguage = (i18n: i18n, languageCode: string) => {
  i18n.changeLanguage(languageCode);
};

export const getCachedLanguage = (i18n: i18n): string => {
  const cachedLanguage = supportedLanguages.find(
    ({ code }) => code === i18n.resolvedLanguage
  );

  const fallbackLanguage = supportedLanguages.find(
    ({ code }) => code === FALLBACK_LANGUAGE_CODE
  )!;

  return cachedLanguage?.code || fallbackLanguage.code;
};

export function connectToSuspense<T>(promise: Promise<T>) {
  let status = "pending";
  let response: T | Error;

  const suspender = promise.then(
    res => {
      status = "success";
      response = res;
    },
    err => {
      status = "error";
      response = err;
    }
  );

  const read = () => {
    switch (status) {
      case "pending":
        throw suspender;
      case "error":
        return response;
      default:
        return response;
    }
  };

  return { read };
}

export const onLoginSuccess = () => {
  // redirect to profile
  window.location.href = PROFILE_URL;
  auth.signOut();
};

export const translateError = (error: unknown): string => {
  let translatedError = i18next.t("errors.unknownError");
  if (error instanceof FirebaseError) {
    translatedError = i18next.t(`errors.${error.code}`);
  }
  if (error instanceof ServerError) {
    translatedError = error.message;
  }

  return translatedError;
};
