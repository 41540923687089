import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";

type Props = TextFieldProps & {
  externalShowPassword?: boolean;
  setExternalShowPassword?: (showPassword: boolean) => void;
};

const PasswordField = ({
  externalShowPassword,
  setExternalShowPassword,
  ...props
}: Props) => {
  const [internalShowPassword, setInternalShowPassword] = useState(false);

  const isStateProvided =
    externalShowPassword !== undefined && setExternalShowPassword !== undefined;

  const togglePasswordVisibility = () => {
    if (isStateProvided) {
      setExternalShowPassword(!externalShowPassword);
      return;
    }
    setInternalShowPassword(!internalShowPassword);
  };

  const showPassword = isStateProvided
    ? externalShowPassword
    : internalShowPassword;

  return (
    <TextField
      fullWidth
      type={showPassword ? "text" : "password"}
      sx={{ mb: 1 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
