import { Box, CircularProgress } from "@mui/material";

const LoadingOverlay = ({ isLoading }: { isLoading: boolean }) => {
  return isLoading ? (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : null;
};

export default LoadingOverlay;
