import { auth } from "@/lib/firebase";
import { isPhoneValid, onLoginSuccess, translateError } from "@/lib/utils";
import UserService from "@/services/user";
import { LOGIN_ROUTE, USER_TOKEN } from "@/shared/constants";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import BackButton from "./BackButton";
import LoadingOverlay from "./LoadingOverlay";
import CustomPhoneInput from "./PhoneInput";

// validate inputs on submit and onChange
export default function FinishGoogleAuth() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [wasValidated, setWasValidated] = useState(false);
  const [phone, setPhone] = useState("");
  const phoneRef = useRef<HTMLInputElement>(null);
  // error is ref, not state, because it's value shouldn't be changing together with inputs. And setWasValidated will trigger re-render anyway when error should be shown
  const errorRef = useRef<string | null>(null);

  const userToken = sessionStorage.getItem(USER_TOKEN);

  // redirect to login if user hasn't logged in through google
  useEffect(() => {
    if (auth.currentUser === null) {
      navigate(LOGIN_ROUTE, { replace: true });
    }
  }, [userToken, navigate]);

  const onError = (error: unknown) => {
    errorRef.current = translateError(error);
    console.error(error);

    // reset errors, since server return an error, any field could be invalid
    setWasValidated(false);
  };

  const signInMutation = useMutation({
    mutationFn: async (phone: string) => {
      await UserService.login({ idToken: userToken!, phone });
    },
    onSuccess: onLoginSuccess,
    onError,
  });

  const handlePhoneChange = (phoneValue: string) => {
    setPhone(phoneValue);

    validatePhone(phoneValue);
  };

  const validatePhone = (phone: string): boolean => {
    if (!isPhoneValid(phone)) {
      phoneRef.current?.setCustomValidity(t("errors.invalidPhone"));
      return true;
    } else {
      phoneRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    errorRef.current = null;
    setWasValidated(true);

    const form = event.currentTarget;

    validatePhone(phone);
    if (!form.checkValidity()) {
      return;
    }

    signInMutation.mutate(phone);
  };

  const handleNavigateBack = () => {
    sessionStorage.removeItem(USER_TOKEN);
    auth.currentUser?.delete();
    navigate(`/${LOGIN_ROUTE}`);
  };

  return (
    <AuthWrapper>
      <LoadingOverlay isLoading={signInMutation.isPending} />

      <Box component={"form"} noValidate sx={{ p: 6 }} onSubmit={handleSubmit}>
        <BackButton
          to={`/${LOGIN_ROUTE}`}
          onClick={handleNavigateBack}
          text={t("shared.backToLogin")}
        />

        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          {t("finishOauth.title")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t("finishOauth.subtitle")}
        </Typography>

        <Stack spacing={3}>
          {errorRef.current !== null && (
            <Alert severity="error"> {errorRef.current} </Alert>
          )}
          <CustomPhoneInput
            value={phone}
            onChange={handlePhoneChange}
            label={t("signUp.phone")}
            error={wasValidated && phoneRef.current?.validity.valid === false}
            errorMessage={
              wasValidated ? phoneRef.current?.validationMessage : ""
            }
            ref={phoneRef}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            {t("finishOauth.submit")}
          </Button>
        </Stack>
      </Box>
    </AuthWrapper>
  );
}
