import { RouterState } from "@/interfaces/Router";
import { auth } from "@/lib/firebase";
import {
  isEmailValid,
  isPasswordValid,
  isPhoneValid,
  onLoginSuccess,
  translateError,
} from "@/lib/utils";
import UserService from "@/services/user";
import { LOGIN_ROUTE } from "@/shared/constants";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import BackButton from "./BackButton";
import LoadingOverlay from "./LoadingOverlay";
import PasswordField from "./PasswordField";
import CustomPhoneInput from "./PhoneInput";

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation() as { state: RouterState };
  const emailFromRouter = state?.email || "";

  const [wasValidated, setWasValidated] = useState(false);
  const [email, setEmail] = useState(emailFromRouter);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // error is ref, not state, because it's value shouldn't be changing together with inputs. And setWasValidated will trigger re-render anyway when error should be shown
  const errorRef = useRef<string | null>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const rePasswordRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);

  const createUserMutation = useMutation({
    mutationFn: async ({
      email,
      password,
      phone,
      name,
    }: {
      email: string;
      password: string;
      phone: string;
      name: string;
    }) => {
      // create user on firebase
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(user, { displayName: name });

      // create token cookie on backend
      const idToken = await user.getIdToken();
      await UserService.login({ idToken, phone });

      return user;
    },
    onSuccess: onLoginSuccess,
    onError: (error: unknown) => {
      errorRef.current = translateError(error);
      console.error(error);

      // reset errors, since server return an error, any field could be invalid
      setWasValidated(false);
    },
  });

  const navigateTo = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: string
  ) => {
    event.preventDefault();

    const emailValue = emailRef.current?.value || "";
    const state: RouterState = { email: emailValue };

    navigate(route, {
      state,
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    validateEmail(emailValue);
  };

  const validateEmail = (email: string): boolean => {
    if (!isEmailValid(email)) {
      emailRef.current?.setCustomValidity(t("errors.invalidEmail"));
      return true;
    } else {
      emailRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);

    validatePassword(passwordValue);
    validatePasswordsEquality(passwordValue, rePassword);
  };

  const validatePassword = (password: string): boolean => {
    if (!isPasswordValid(password)) {
      passwordRef.current?.setCustomValidity(t("errors.invalidPassword"));
      return true;
    } else {
      passwordRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleRePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rePasswordValue = event.target.value;
    setRePassword(rePasswordValue);

    validatePasswordsEquality(password, rePasswordValue);
  };

  const validatePasswordsEquality = (
    password: string,
    rePassword: string
  ): boolean => {
    if (password !== rePassword) {
      rePasswordRef.current?.setCustomValidity(t("errors.passwordMismatch"));
      return true;
    } else {
      rePasswordRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = event.target.value;
    setName(nameValue);

    validateName(nameValue);
  };

  const handlePhoneChange = (phoneValue: string) => {
    setPhone(phoneValue);

    validatePhone(phoneValue);
  };

  const validatePhone = (phone: string): boolean => {
    if (!isPhoneValid(phone)) {
      phoneRef.current?.setCustomValidity(t("errors.invalidPhone"));
      return true;
    } else {
      phoneRef.current?.setCustomValidity("");
      return false;
    }
  };

  const validateName = (name: string): boolean => {
    if (!name) {
      nameRef.current?.setCustomValidity(t("errors.invalidName"));
      return true;
    } else {
      nameRef.current?.setCustomValidity("");
      return false;
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async event => {
    event.preventDefault();
    errorRef.current = null;
    setWasValidated(true);

    const form = event.currentTarget;

    validateEmail(email);
    validatePassword(password);
    validatePasswordsEquality(password, rePassword);
    validateName(name);
    if (!form.checkValidity()) {
      return;
    }

    createUserMutation.mutate({
      email,
      password,
      phone,
      name,
    });
  };

  return (
    <AuthWrapper>
      <LoadingOverlay isLoading={createUserMutation.isPending} />

      <Box component={"form"} noValidate sx={{ p: 6 }} onSubmit={handleSubmit}>
        <BackButton
          to={`/${LOGIN_ROUTE}`}
          onClick={event => navigateTo(event, `/${LOGIN_ROUTE}`)}
          text={t("shared.backToLogin")}
        />

        <Typography variant="h3" sx={{ marginBottom: 1 }}>
          {t("signUp.title")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t("signUp.subtitle")}
        </Typography>

        <Stack spacing={3}>
          {errorRef.current !== null && (
            <Alert severity="error">{errorRef.current}</Alert>
          )}

          <TextField
            label="Email"
            id="email"
            type="email"
            inputRef={emailRef}
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
            error={wasValidated && emailRef.current?.validity.valid === false}
            helperText={wasValidated && emailRef.current?.validationMessage}
          />

          <CustomPhoneInput
            value={phone}
            onChange={handlePhoneChange}
            label={t("signUp.phone")}
            error={wasValidated && phoneRef.current?.validity.valid === false}
            errorMessage={
              wasValidated ? phoneRef.current?.validationMessage : ""
            }
            ref={phoneRef}
          />

          <PasswordField
            label={t("shared.password")}
            id="password"
            value={password}
            onChange={handlePasswordChange}
            inputRef={passwordRef}
            error={
              wasValidated && passwordRef.current?.validity.valid === false
            }
            helperText={wasValidated && passwordRef.current?.validationMessage}
            externalShowPassword={showPassword}
            setExternalShowPassword={setShowPassword}
          />

          <PasswordField
            label={t("signUp.rePassword")}
            id="repassword"
            value={rePassword}
            onChange={handleRePasswordChange}
            inputRef={rePasswordRef}
            error={
              wasValidated && rePasswordRef.current?.validity.valid === false
            }
            helperText={
              wasValidated && rePasswordRef.current?.validationMessage
            }
            externalShowPassword={showPassword}
            setExternalShowPassword={setShowPassword}
          />

          <TextField
            label={t("signUp.name")}
            id="username"
            type="text"
            value={name}
            onChange={handleNameChange}
            inputRef={nameRef}
            variant="outlined"
            fullWidth
            error={wasValidated && nameRef.current?.validity.valid === false}
            helperText={wasValidated && nameRef.current?.validationMessage}
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            {t("signUp.submit")}
          </Button>
        </Stack>
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
