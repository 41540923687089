import { ServerError } from "@/shared/errors";
import { API_URL } from "../shared/environmentVariables";

const USER_API_URL = `${API_URL}/user`;

class UserServiceClass {
  private async sendRequest(
    endpoint: string,
    method: "POST",
    bodyContent?: any
  ) {
    const headersList = {
      "Content-Type": "application/json",
    };
    const body =
      bodyContent !== undefined ? JSON.stringify(bodyContent) : undefined;

    const response = await fetch(`${USER_API_URL}/${endpoint}`, {
      method,
      body,
      headers: headersList,
      credentials: "include",
    });

    if (!response.ok) {
      throw new ServerError({
        status: response.status,
        message: response.statusText,
      });
    }

    return response;
  }

  /**
   * Create httpOnly token cookie from firebase tokenId
   * @throws {ServerError} the response wasn't successful
   * @return {true} true if cookie was created
   */
  async login({
    idToken,
    phone,
  }: {
    idToken: string;
    phone?: string;
  }): Promise<true> {
    const bodyContent = { idToken, phone };

    await this.sendRequest("login", "POST", bodyContent);
    return true;
  }

  /**
   * Check if httpOnly cookie with firebase token is valid
   * @throws {ServerError} the response wasn't successful
   * @return {Promise<boolean>} true if the session is valid, false if the user hasn't logged in yet (401 error).
   */
  async checkSession(): Promise<boolean> {
    try {
      await this.sendRequest("session", "POST");
    } catch (error) {
      // don't throw error if it's 401 for session check, because user hasn't logged in yet
      const isErrorHidden =
        error instanceof ServerError && error.status === 401;

      if (isErrorHidden) return false;

      throw error;
    }
    return true;
  }
}

const UserService = new UserServiceClass();

export default UserService;
