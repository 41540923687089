export class ClientError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name; // Set the error name to the class name

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class ServerError extends Error {
  public readonly status: number;

  constructor({ status, message }: { status: number; message: string }) {
    super(`${status}: ${message}`);
    this.status = status;
    this.name = this.constructor.name; // Set the error name to the class name

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
